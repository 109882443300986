import './content.scss';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

const locaArray = [
  {id: 1, items:'3', cat:'kirche', name:'Liebfrauen Kirche', desc:'Die seit 1896 bestehende Kirche mitten im Herzen von Gelsenkirchen war zeitgleich auf der Fundort der ersten Steinkohle innerhalb Gelsenkirchens: Wiehagen.<br />Die Liebfrauenkirche zeigt sich als romanische Basilika und ist Sitz der jungen Kirche des Bistum Essen in Gelsenkirchen.', getTo:'<b>...mit Zug</b>: Hauptbahnhof Gelsenkirchen ist ca. 150m entfernt.<br><b>...mit ÖPNV</b>: Die Buslinien 385 / 389 halten direkt vor der Kirche.<br><b>...mit Auto:</b> Über die A40 (Abfahrt Gelsenkirchen Süd); von da wenige km über die 227 bis zur Innenstadt (ca. 5-10 min)', parking:'Nur wenige Parkplätze auf dem Kirchengelände selbst, aber diverse Parkplätze an der Straße in direkter Nähe der Kirche. Parkhaus (Hauptbahnhof-Süd) fußläufig ca. 100m entfernt.', img:'church-contact.jpg', conti:'', priceCat:'0', price:'', tel: '020992585801', web: 'http://www.propstei-ge.de/st-augustinus/unsere-kirchen/filialkirche-liebfrauen', locaStreet: 'Stolzestraße', locaStreetNo: '1', locaZip: '45879', locaCity: 'Gelsenkirchen', infoGetToLink: 'https://www.google.com/maps/dir//Liebfrauenkirche,+Stolzestra%C3%9Fe+1,+Gelsenkirchen-S%C3%BCd+45879+Gelsenkirchen/@51.5035929,7.0704383,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x47b8e71bcf6f16b9:0x6bf16df6e7bc85b9!2m2!1d7.1054534!2d51.5035052!3e3'},
  {id: 2, items:'4', cat:'hotel', name:'Loemühle', desc:'Das Landhotel Loemühle im gleichnamigen Tal ist eine moderne Unterkunft innerhalb herrlicher Fachwerkgebäude. Neben komfortable Zimmern und guter Verköstigung bietet es auch eine wunderschöne Wellness-Oase und zahlreiche Aktivitäten in der Umgebung an.', getTo:'<b>...mit ÖPNV</b>: Die Buslinien 220 / 222 sowie der NE6 halten ca. 10 min entfernt.<br><b>...mit Auto:</b> von der Location eine 4 km (ca. 10 min) Autofahrt entfernt. Anbindung an die A43 (Marl Sinsen)', parking:'Als weitläufiges Holtel hat die Loemühle ausreichend Parkplätze für alle Übernachtungsgäste. Notfalls gibt es noch Parkplätze in der direkten Umgebung.', img:'hotel-2.jpg', conti:'15', priceCat:'4', price:'105', tel: '02365-41450', web: 'https://www.loemuehle.com', locaStreet: 'Loemühlenweg', locaStreetNo: '221', locaZip: '45770', locaCity: 'Marl', infoGetToLink: 'https://www.google.com/maps/dir//Hotel+%26+Gastropark+Loem%C3%BChle,+Loem%C3%BChlenweg+221,+45770+Marl/@51.6549597,7.1311506,17z/data=!4m19!1m9!3m8!1s0x47b8fb23d12d687d:0xd04287410d98fbd1!2sHotel+%26+Gastropark+Loem%C3%BChle!5m2!4m1!1i2!8m2!3d51.6548797!4d7.1333059!4m8!1m0!1m5!1m1!1s0x47b8fb23d12d687d:0xd04287410d98fbd1!2m2!1d7.1333059!2d51.6548797!3e3'},
  {id: 3, items:'4', cat:'hotel', name:'Alte Schule', desc:'Inmitten von Feldern und Weiden liegt das Gästehaus Alte Schule. Beherbergt in einem ehemaligen Schulgebäude aus dem Jahre 1902, verbindet es auf gekonnte Weise historisches Flair mit modernen Annehmlichkeiten.', getTo:'<b>...mit ÖPNV</b>: Die Buslinien 385 / 389 halten direkt vor der Kirche.<br><b>...mit Auto:</b> von der Location eine 3 km (ca. 5 min) Autofahrt entfernt. Anbindung an die A43 (Marl Sinsen)', parking:'Nur wenige Parkplätze auf dem Kirchengelände selbst, aber diverse Parkplätze an der Straße in direkter Nähe der Kirche. Parkhaus (Hauptbahnhof-Süd) fußläufig ca. 100m entfernt.', parking:'Hat einen eigenen Parkplatz direkt auf dem Hof, auf dem ca. 10-15 Autos Platz finden.', img:'hotel-1.jpg', conti:'12', priceCat:'2', price:'80', tel: '02361-4064825', web: 'https://www.alte-schule-recklinghausen.de', locaStreet: 'Bockholter Str.', locaStreetNo: '385', locaZip: '45659', locaCity: 'Recklinghausen', infoGetToLink: 'https://www.google.com/maps/dir//Alte+Schule,+Bockholter+Stra%C3%9Fe,+Recklinghausen/@51.633437,7.1210116,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x47b8e4b326d60e5f:0xba325482096d35b6!2m2!1d7.1561031!2d51.6333872!3e3'},
  {id: 4, items:'3', cat:'location', name:'Spargelhof Schulte Scherlebeck', desc:'Der Spargelhof Schulte-Scherlebeck liegt inmitten einer herrlichen Naturlandschaft am Rande des nördlichen Ruhrgebiets. Bereits im 13. Jahrhundert wurde der Hof das erste Mal in der Sebastianus-Gilde Herten erwähnt und war seit der Zeit immer in Familienbesitz.<br />Wir werden in der Festscheune auf dem Hofgelände feiern. Nach Schließen des Hofladens dürfte auch das Hofgelände weitläufig nutzbar sein.', getTo:'<b>...mit Zug</b>: von der Kirche kann man bequem zum Hauptbahnhof Gelsenkirchen laufen, um von da bis Recklinghausen HBF zu gelangen (von da ab per ÖPNV)<br><b>...mit ÖPNV</b>: Die Buslinien 214 / 219 / SB25 und der NE3 halten etwa 10 min entfernt vom Spargelhof.<br><b>...mit Auto:</b> Über die A40 (Abfahrt Gelsenkirchen Süd); von da wenige km über die 227 bis zur Innenstadt (ca. 5-10 min)', parking:'Nur wenige Parkplätze auf dem Kirchengelände selbst, aber diverse Parkplätze an der Straße in direkter Nähe der Kirche. Parkhaus (Hauptbahnhof-Süd) fußläufig ca. 100m entfernt.', parking:'Parkplatz vor dem Hof sollte genug Platz haben für ca. 50 Autos. In der Spargel-Saison wird dieser sogar noch erweitert, allerdings können durch den Publikumsverkehr in der Saison ggf. viele Plätze temporär belegt sein. Nach Absprache mit den Besitzern ist es auch möglich, dass ihr euer Wohnmobil hier abstellt - falls vorhanden.', img:'location-revealed.jpg', conti:'', priceCat:'0', price:'', tel: '0236642446', web: 'http://www.spargelhof-schulte-scherlebeck.de', locaStreet: 'Scherlebecker Str.', locaStreetNo: '435', locaZip: '45701 ', locaCity: 'Herten', infoGetToLink: 'https://www.google.com/maps/dir//Spargelhof+Schulte-Scherlebeck,+Scherlebecker+Stra%C3%9Fe,+Herten/@51.62997,7.1028819,13z/data=!3m2!4b1!5s0x47b8e4cea5e15235:0x55e9da306c7448c2!4m9!4m8!1m0!1m5!1m1!1s0x47b8e4cea60ffe9f:0xc62a9cca3df75d69!2m2!1d7.137987!2d51.629922!3e3'}
];

const contacts = locaArray.map(({id, items, cat, name, desc, getTo, parking, img, conti, priceCat, price, tel, web, locaStreet, locaStreetNo, locaZip, locaCity, infoGetToLink}) =>
  <div className="wrap-card"><div className={"card card-info cat-" + name} key={id}>
    <img src={'gfx/' + img} class="card-img-top" alt={"Bild von " + name} />
    <div class="card-img-overlay">
        <figure class="card-title">
            <h5>{name} <span>{cat}</span></h5>
        </figure>
    </div>
  </div>
  <div class={"logisticsInfo items" + items}>
      <Tab.Container defaultActiveKey={id + "tab" + "first"}>
          <Nav className="flex-row justify-content-center small nav-pills">
            <Nav.Item>
              <Nav.Link eventKey={id + "tab" + "first"}>Info</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={id + "tab" + "second"}>Zimmer</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={id + "tab" + "third"}>Anreise</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={id + "tab" + "fourth"}>Parken</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="subTabs">
            <Tab.Pane eventKey={id + "tab" + "first"} className="infoDesc">
                <p dangerouslySetInnerHTML={{__html: desc }}></p>
            </Tab.Pane>
            <Tab.Pane eventKey={id + "tab" + "second"} className="infoPrice">
                <div className={"price price" + priceCat + " d-inline-block px-3"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                </div>
                <div className="small">
                    (DZ ab {price}€)
                </div>
                <div className="small">
                    {conti} Zimmer vorreserviert
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey={id + "tab" + "third"} className="infoGetTo">
              <p dangerouslySetInnerHTML={{__html: getTo }}></p>
              <div><a href={infoGetToLink} target="_blank">Google Maps Anfahrt</a></div>
            </Tab.Pane>
            <Tab.Pane eventKey={id + "tab" + "fourth"} className="infoParking">
              <p dangerouslySetInnerHTML={{__html: parking }}></p>
            </Tab.Pane>
          </Tab.Content>
          <div className="d-flex justify-content-between align-items-center more-info">
            <span className="small">Weitere Infos / Kontakt:</span>
            <ul className="list-unstyled d-flex justify-content-end m-0">
                <li className="d-block d-md-none">
                  <a href={infoGetToLink} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></a>
                </li>
                <li className="d-block d-md-none">
                  <a href={"tel:" + tel} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></a>
                </li>
                <li>
                  <a href={web} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg></a>
                </li>
            </ul>
          </div>
      </Tab.Container>
  </div>
  </div>
)
const contactsHide = locaArray.map(({id, items, cat, name, desc, getTo, parking, img, conti, priceCat, price, tel, web, locaStreet, locaStreetNo, locaZip, locaCity}) =>
  <div className={"card card-info card-info-later"} key={id}>
    <img src={'gfx/' + img} class="card-img-top" alt={"Bild von " + name} />
    <div class="card-img-overlay">
        <figure class="card-title">
            <h5>{name} <span>{cat}</span></h5>

        </figure>
    </div>

  </div>
)

function ContentLogistic() {
  return (
    <div className="loca">
      <aside className="jumbotron p-0 text-center">
        <figure className="banner">
          <img src="gfx/logistics.jpg" className="img-fluid" alt="Gallerie Banner" />
          <h2>Anreise & Unterbringung</h2>
        </figure>

      </aside>

      <section>
        <h3>Kirche</h3>
        {contacts[0]}
      </section>

      <section>
        <h3>Location</h3>
        {contacts[3]}
      </section>

      <section>
        <h3>Unterbringungen</h3>
        <div className="row">
            <div className="col-12 col-md-6">
                {contacts[1]}
            </div>
            <div className="col-12 col-md-6">
                {contacts[2]}
            </div>
        </div>
      </section>
    </div>
  );
}

export default ContentLogistic;
