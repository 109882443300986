import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import './header.scss';
import './footer.scss';

import MainNavigation from './partials/MainNavigation';
import Cookies from 'universal-cookie';

import ContentInfo from './partials/ContentInfo';
import ContentABC from './partials/ContentABC';
import ContentLogistic from './partials/ContentLogistic';
import ContentGallery from './partials/ContentGallery';
import ContentContact from './partials/ContentContact';

const cookies = new Cookies();

/*
import Header from './components/privateSection/header';
import Footer from './components/privateSection/footer';
*/

class SecureContent extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  logout() {
    cookies.remove('weddingCookie', { path: '/', sameSite: 'Strict' });
    window.location.reload();
  }

  render() {

    return (
      <div className="App h-100">
        <section className="h-100 d-flex flex-column">

          <Tab.Container defaultActiveKey="first">
            <header>
              <div className="navbar fixed-top box-shadow justify-content-center">
                <div className="container d-flex justify-content-between">
                  <Nav className="">
                    <Nav.Item className="newInfo">
                      <Nav.Link eventKey="first">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="nonfeather"><path d="M7 13.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm9 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm4-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-17.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2zm19.318 3.168c-.761-1.413-1.699-3.17-2.684-4.812-.786-1.312-1.37-1.938-2.751-2.187-1.395-.25-2.681-.347-4.585-.347s-3.19.097-4.585.347c-1.381.248-1.965.875-2.751 2.187-.981 1.637-1.913 3.382-2.684 4.812-.687 1.273-.98 2.412-.98 3.806 0 1.318.42 2.415 1 3.817v2.209c0 .552.448 1 1 1h1.5c.552 0 1-.448 1-1v-1h13v1c0 .552.448 1 1 1h1.5c.552 0 1-.448 1-1v-2.209c.58-1.403 1-2.499 1-3.817 0-1.394-.293-2.533-.98-3.806zm-15.641-3.784c.67-1.117.852-1.149 1.39-1.246 1.268-.227 2.455-.316 4.231-.316s2.963.088 4.231.316c.538.097.72.129 1.39 1.246.408.681.81 1.388 1.195 2.081-1.456.22-4.02.535-6.816.535-3.048 0-5.517-.336-6.805-.555.382-.686.779-1.386 1.184-2.061zm11.595 10.616h-11.948c-1.671 0-3.026-1.354-3.026-3.026 0-1.641.506-2.421 1.184-3.678 1.041.205 3.967.704 7.816.704 3.481 0 6.561-.455 7.834-.672.664 1.231 1.166 2.01 1.166 3.646 0 1.672-1.355 3.026-3.026 3.026zm5.526-10c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202z"/></svg>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="newInfo">
                      <Nav.Link eventKey="fourth">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-camera"><path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path><circle cx="12" cy="13" r="4"></circle></svg>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail">
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                      </Nav.Link>
                    </Nav.Item>

                  </Nav>
                  <figure className="mb-0" onClick={this.logout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                  </figure>
                </div>
                <figure id="raute"></figure>
              </div>
              <h1 className="mb-0">Willkommen <span>auf unserer Hochzeits-Website</span></h1>
            </header>

            <main role="main">
              <div className="album pb-5">
                <div className="container">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <ContentInfo />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <ContentABC />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <ContentLogistic />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <ContentGallery />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <ContentContact />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </main>

            <footer className="text-muted bg-light mt-auto">
              <div className="container">
                <Nav className="flex-row justify-content-center small" id="nav-footer">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Unser Tag</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Hochzeits-ABC</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Anreise & Unterbringung</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">Fotos & Inspirationen</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth">Kontakt</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </footer>

          </Tab.Container>
        </section>

      </div>
    )
  }
}

export default SecureContent;
