const contactArray = [
  {id: 1, fname: 'Samina', lname: 'Salewsky', telpre: '0178', tel: '1369756', mail: 's.rajput@web.de', img: 'samina'},
  {id: 2, fname: 'Robert', lname: 'Salewsky', telpre: '01575', tel: '3650299', mail: 'r.salewsky@email.de', img: 'robert'},
  {id: 3, fname: 'Steffi', lname: 'Schaefer', telpre: '01575', tel: '6316871', mail: 'Schaefer-steph@web.de', img: 'steffi'},
  {id: 4, fname: 'Lars', lname: 'Bissa', telpre: '0177', tel: '3462837', mail: 'lars_frangenberg@web.de', img: 'lars'},
  {id: 5, fname: 'Daniel', lname: 'Gross', telpre: 'd-none', tel: '', mail: 'abel001@gmx.de', img: 'daniel'},
  {id: 6, fname: 'Marc', lname: 'Schaefer', telpre: 'd-none', tel: '', mail: 'kadaqp@hotmail.com', img: 'marc'}
];

const contacts = contactArray.map(({id, fname, lname, telpre, tel, mail, img}) =>
  <div className="card mb-3" key={id}>
    <img src={'gfx/' + img + '.jpg'} alt={fname + ' ' + lname} className="card-img-top" />
    <h5 className="card-title text-center">{fname} <span className="d-none d-md-inline">{lname}</span></h5>

    <ul className="list-unstyled small d-none d-lg-flex justify-content-center align-items-center flex-column">
      <li className={"px-3 d-flex flex-column justify-content-center align-items-center mb-3 " + telpre}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg> {telpre} / {tel}
      </li>
      <li className="px-3 d-flex flex-column justify-content-center align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail mr-2"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
        <a href={'mailto:' + mail}>{mail}</a>
      </li>
    </ul>

    <ul className="list-unstyled d-flex justify-content-center d-lg-none">
      <li className={"mr-3 " + telpre}>
        <a href={'tel:' + telpre + '-' + tel}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
        </a>
      </li>
      <li>
        <a href={'mailto:' + mail}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
        </a>
      </li>
    </ul>
  </div>
)

function ContentContact() {
  return (
    <div>
    <aside className="jumbotron p-0 text-center">
      <figure className="banner">
        <img src="gfx/kontakt.jpg" className="img-fluid" alt="Kontakt Banner" />
        <h2>Kontakt zu uns oder unseren Trauzeugen</h2>
      </figure>

    </aside>

    <section className="pt-2">

      <div className="card-deck card-deck-content mb-sm-3">
        {contacts[0]}
        {contacts[1]}
      </div>

      <div className="card-deck">
        <div className="card border-none mb-0">
          <div className="card-deck card-deck-content">
            {contacts[2]}
          </div>
        </div>
        <div className="card border-none">
          <div className="card-deck card-deck-content flex-column flex-lg-row h-100">
            {contacts[3]}
            {contacts[4]}
            {contacts[5]}
          </div>
        </div>
      </div>


    </section>
    </div>
  );
}

export default ContentContact;
