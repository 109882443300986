function IconLeaf() {
  return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="25" height="25" viewBox="0 0 1280.000000 1078.000000"
       preserveAspectRatio="xMidYMid meet" className="leaf">
      <g transform="translate(0.000000,1078.000000) scale(0.100000,-0.100000)">
      <path d="M4423 10758 c-35 -38 -215 -367 -285 -520 -111 -241 -204 -511 -242
      -698 -77 -384 -23 -793 168 -1254 73 -177 101 -221 173 -281 37 -31 69 -52 70
      -48 2 4 23 253 48 553 73 882 105 1253 112 1290 6 32 71 -1749 72 -1982 1 -54
      4 -98 9 -98 7 1 40 42 137 169 60 79 113 207 184 442 86 289 126 494 140 731
      23 365 -49 755 -214 1159 -66 163 -124 271 -224 422 -96 145 -110 156 -148
      115z"/>
      <path d="M150 9764 c-8 -1 -46 -6 -84 -9 l-69 -7 7 -54 c8 -70 32 -129 102
      -250 214 -371 650 -837 999 -1067 176 -115 354 -188 595 -241 209 -46 539 -85
      892 -106 170 -10 181 -9 305 15 70 14 130 25 133 25 3 0 -171 100 -385 223
      -425 243 -709 409 -850 495 -158 96 -326 205 -322 209 6 5 271 -107 672 -285
      579 -256 810 -342 919 -343 49 -1 76 16 76 46 0 14 -145 260 -210 355 -89 132
      -254 311 -385 417 -120 97 -356 230 -536 301 -206 82 -455 144 -749 186 -343
      50 -1038 106 -1110 90z"/>
      <path d="M6458 9334 c-54 -16 -78 -44 -161 -179 -153 -252 -274 -548 -387
      -946 -157 -553 -178 -1102 -63 -1619 30 -136 128 -435 176 -541 38 -82 68
      -119 97 -119 64 0 97 182 185 1005 51 482 97 867 102 861 2 -2 9 -413 15 -912
      5 -500 11 -910 12 -911 0 0 18 -4 38 -8 l37 -7 37 73 c99 198 241 750 290
      1129 36 280 34 719 -5 1020 -38 296 -79 460 -227 913 -41 128 -77 238 -79 245
      -5 15 -8 14 -67 -4z"/>
      <path d="M3305 8196 c-44 -19 -54 -36 -72 -121 -15 -75 -15 -77 8 -122 20 -38
      38 -53 114 -97 106 -61 153 -95 380 -277 329 -264 759 -642 905 -795 188 -198
      791 -935 1283 -1569 738 -949 828 -1056 1127 -1329 188 -172 619 -551 709
      -624 118 -94 394 -252 694 -397 356 -171 681 -297 1112 -431 714 -222 1330
      -352 1990 -418 253 -26 789 -57 1002 -59 246 -2 239 -6 236 112 -1 94 -10 113
      -66 145 -89 52 -240 79 -687 121 -561 53 -1047 128 -1635 255 -550 118 -878
      219 -1361 420 -384 160 -541 244 -752 402 -61 46 -170 115 -243 153 -205 109
      -460 312 -763 605 -230 224 -298 308 -1296 1610 -761 993 -838 1091 -967 1236
      -135 152 -550 569 -629 633 -196 159 -699 472 -856 534 -75 30 -180 36 -233
      13z"/>
      <path d="M8512 7618 c-27 -29 -255 -505 -320 -668 -141 -354 -242 -784 -287
      -1230 -46 -444 31 -1234 151 -1555 15 -38 46 -108 71 -155 51 -100 301 -448
      307 -429 5 18 72 692 91 914 25 304 54 929 56 1210 1 83 4 175 9 205 l7 55 2
      -58 c1 -51 64 -1126 120 -2060 10 -169 21 -310 24 -313 3 -3 49 56 102 130
      126 177 184 305 307 686 155 482 198 847 198 1713 l0 457 -187 308 c-315 519
      -447 719 -519 781 -44 39 -100 43 -132 9z"/>
      <path d="M2720 7504 c-252 -58 -535 -151 -695 -227 -253 -122 -453 -276 -783
      -601 l-232 -230 32 -34 c18 -18 46 -40 61 -49 41 -21 191 -48 427 -77 365 -44
      451 -50 710 -51 l255 0 147 37 c227 58 494 150 633 219 148 72 303 174 552
      362 182 136 182 137 171 165 -7 15 -15 31 -18 35 -4 4 -206 -28 -448 -72 -243
      -44 -553 -99 -689 -121 -137 -22 -257 -42 -267 -45 -10 -2 -15 0 -10 4 5 5 79
      29 164 55 527 160 1049 335 1141 381 85 43 68 99 -39 128 -77 21 -260 50 -547
      87 -132 17 -280 38 -330 45 -112 18 -108 19 -235 -11z"/>
      <path d="M11235 7005 c-156 -84 -313 -448 -465 -1079 -98 -408 -140 -690 -156
      -1051 -12 -288 43 -760 121 -1030 52 -179 160 -416 268 -587 56 -88 309 -440
      313 -435 2 2 14 932 31 2517 4 394 10 643 15 610 4 -30 31 -275 58 -545 28
      -269 78 -746 111 -1060 51 -486 79 -748 161 -1472 6 -57 15 -103 20 -103 4 0
      8 4 8 8 0 5 48 98 107 208 59 109 119 228 134 264 94 221 162 612 199 1145 27
      395 33 535 34 815 l1 305 -32 120 c-43 159 -122 407 -166 523 -119 305 -377
      682 -553 808 -57 41 -75 48 -123 51 -38 3 -66 -1 -86 -12z"/>
      <path d="M4095 5784 c-364 -34 -581 -115 -1055 -391 -345 -202 -417 -266 -505
      -447 -70 -147 -85 -185 -75 -191 18 -11 706 -176 833 -199 218 -41 410 -57
      662 -57 509 -1 910 88 1384 308 155 71 204 107 301 215 50 55 89 102 87 104
      -2 2 -41 -3 -88 -11 -113 -19 -444 -45 -1279 -100 -47 -3 -103 -9 -125 -12
      -22 -3 -37 -2 -34 1 9 10 352 70 661 116 614 92 758 135 758 225 0 27 -8 40
      -37 65 -127 105 -674 298 -1010 356 -84 14 -394 26 -478 18z"/>
      <path d="M5771 3439 c-291 -26 -661 -119 -940 -235 -248 -104 -434 -224 -821
      -529 -151 -120 -277 -219 -280 -222 -3 -3 25 -2 61 2 l66 7 12 -45 c14 -57 65
      -103 218 -200 289 -181 709 -334 1085 -394 158 -25 608 -25 788 0 265 38 521
      102 730 184 155 60 434 198 545 268 85 54 517 379 523 393 1 4 -19 6 -45 4
      -305 -17 -3509 -172 -3517 -169 -6 2 23 7 64 11 267 26 2881 359 3075 391 113
      19 345 64 364 71 26 9 -217 141 -343 188 -142 52 -440 142 -615 186 -338 85
      -673 115 -970 89z"/>
      <path d="M9400 1999 c-119 -10 -194 -24 -295 -56 -172 -54 -499 -205 -701
      -324 -143 -84 -373 -243 -450 -311 -253 -223 -313 -279 -412 -380 -205 -210
      -327 -358 -411 -499 l-51 -84 24 -23 c25 -25 145 -66 498 -167 841 -242 1514
      -200 2207 136 392 190 614 374 1200 997 84 89 149 162 143 162 -5 0 -79 -9
      -163 -20 -229 -30 -568 -119 -1809 -473 -355 -102 -646 -184 -648 -182 -3 2
      257 100 903 338 665 246 971 383 1113 497 109 88 130 156 68 215 -64 60 -333
      141 -566 170 -134 16 -481 19 -650 4z"/>
      </g>
      </svg>
  );
}

export default IconLeaf;
