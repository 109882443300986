import IconLeaf from './IconLeaf';
import IconCountdown from './IconCountdown';
import './content.scss';

function ContentInfo() {
  return (
    <div>
      <aside className="jumbotron p-0 text-center">
        <figure className="banner">
          <img src="gfx/danke.jpg" className="img-fluid" alt="Ablauf Banner" />
          <h2>Dankeschön</h2>
        </figure>

      </aside>

      <section>
        <IconCountdown />

          <div class="hori-timeline" dir="ltr">
              <ul class="list-inline events">
                  <li class="list-inline-item event-list">
                      <div class="px-4">
                            <figure class="event-date">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">
                                    <g id="cCWErQ_1_">
                                        <path d="M114.3,27.4c0.5-2.3,1-3.7,1-5.1c0-5.5,0.3-10.2,7.9-10.3c9.3-0.1,11.1,1,13.5,9.1c0.8,2.7,2.9,6.1,5.2,6.9
                                       c6,2.3,7.5,6.5,7.3,12.1c-0.2,5-2.1,8.2-7.6,9.1c-1.8,0.3-4.4,3-4.7,4.9c-0.9,6.6-0.2,12.7,4.9,18.4c17.3,19.5,34,39.5,51.1,59.2
                                       c3.1,3.6,7.3,6.4,9.9,10.3c2,3,3.8,7.6,3,10.7c-0.6,2.2-5.5,4.2-8.8,4.7c-4.5,0.8-9.3,0.2-15.6,0.2c1.4,17.7,2.7,34.7,4.1,51.8
                                       c0.2,2.2,0.9,4.4,0.9,6.6c0.1,5.7-2.4,8.9-8.6,8.9c-12.5,0-25,0.6-37.6,0.5c-19.8-0.1-39.6-0.4-59.4-0.6c-3.7,0-7.4-0.1-11-0.5
                                       c-10.7-1.3-13.2-4.4-12.6-15.2c0.8-13.6,2.3-27.1,2.4-40.7c0-11.8-1-12-12.3-14c-3-0.5-5.9-2.3-8.8-3.5c1.5-3.1,2.3-7,4.6-9.3
                                       c22.2-22.6,44.5-44.9,66.9-67.2c4.9-4.8,8.3-20.7,4.8-26.5c-1.1-1.8-4.7-2.6-7.3-3c-5.9-1-10.9-6.1-9.8-11.8c0.4-2,3.4-4.3,5.7-5
                                       C106.8,27.2,110.5,27.6,114.3,27.4z M192,149c0.4-1,0.8-1.9,1.1-2.9c-17.3-20.9-34.2-42.2-52.1-62.7c-9.6-11-16.7-10.7-27.3-0.7
                                       C98.1,97.4,83,112.4,67.9,127.5c-5.4,5.3-10.2,11.2-17.1,18.9c6.8,0.9,10.9,1.7,15,2c5,0.3,5.5,2.5,5.1,7.3
                                       c-1.6,16.7-2.5,33.6-3.6,50.4c-0.4,6.4,3.4,9.4,9.4,9.7c6.3,0.3,12.5,0.8,18.8,0.7c12.1-0.1,12-0.3,12.1-12.6
                                       c0.1-11.7-1-23.6,4.1-34.8c3.3-7.3,8.7-11,16.4-10.4c8.8,0.7,15.2,4.6,16.2,12.2c1,7.9,0.4,16,0.1,24
                                       c-0.9,22.2,0.9,23.8,23.1,21.1c6.1-0.7,8.5-3,8-9.1c-1.3-15.7-2.2-31.5-3.8-47.2c-0.8-7.5,0.5-10,8.5-10.7
                                       C184.1,148.7,188.1,149,192,149z M114.2,193.4c0.5,0.1,1,0.3,1.5,0.4c0.3,5.5,0.5,11,1,16.4c0.4,5.3,4.2,6.4,8.6,6.2
                                       c4.4-0.2,9.1,0.1,9.9-6c1-8.3,2.6-16.6,3-24.9c0.2-4.6-0.7-9.6-2.6-13.7c-3.5-7.5-11.5-8.2-15.1-0.9
                                       C117,177.7,116.1,185.8,114.2,193.4z M125.4,62.4c0.5,0.2,1,0.5,1.4,0.7c0.6-2.4,1.7-4.7,1.7-7.1c0-7,1.2-12.5,9.9-12.9
                                       c1.3-0.1,3.8-2.2,3.7-3.1c-0.2-1.8-1.7-4.8-3-5c-7-0.9-8.6-5.9-10-11.4c-0.3-1.2-1.8-2.1-3.1-3.6c-2.3,4.6-3.4,9.9-6.6,12.3
                                       c-3.1,2.3-8.5,1.6-12.8,2.2c5.2,4.8,12.2,6,15.2,10.1C124.9,49.1,124.4,56.4,125.4,62.4z"/>
                                       </g>
                                </svg>

                            </figure>

                            <IconLeaf />
                            <h5 class="font-size-16">Trauung</h5>
                      </div>
                  </li>

                  <li class="list-inline-item event-list">
                      <div class="px-4">
                          <figure class="event-date">

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">

                            <g>
                              <path d="M146,181.5c-23.5-0.6-46.9,0-70.4,1.3c-4.6,0.3-8.9,1.2-13,3.3c-7.9,4-9.5,3.2-10.3-6.1c-1.4-16.5-2.5-33-3.9-49.5
                                  c-1-11.4-2.1-22.8-3.2-34.1c-0.6-5.9,1.4-9.1,7.9-9.1c8.1,0,16.3-0.3,24.2-2.7c3.9-1.2,7.3-0.5,8.7,3.9c1.5,4.5,4.7,5,8.6,4.1
                                  c14.7-3.5,29.8-4,44.8-5.3c6.8-0.6,8.8-2.8,7.7-9.6c-1.7-10.8-0.2-12.3,11-13.5c7.8-0.8,15.6-1.6,23.4-2.3
                                  c3.9-0.4,7.6,0.2,8.4,4.8c1.4,8,4.3,15.6,4.4,23.8c0.4,20.9,6.3,41,9.1,61.5c3.5,26.1,3.5,26.9-23.1,28
                                  C168.9,180.3,157.4,180.9,146,181.5z M118,177c22.3-1,44.5,0.2,66.7-3.1c13.9-2.1,15-3.4,13-17.3c-2.8-19.3-6.3-38.6-8.5-58
                                  c-1-9.1-4.3-11.7-13.2-10.7c-38.2,4.6-76.5,8.8-114.7,12.8c-7,0.7-9.4,3.8-8.7,10.6c2,18.8,4.1,37.7,5.2,56.6
                                  c0.5,7.9,3.7,9.4,10.6,9.2C84.9,176.8,101.5,177,118,177z M168,84.1c4.2-0.3,8.1-0.5,12-0.9c4.9-0.5,6.5-3.4,6-8.1
                                  c-0.5-4.4-1.9-7.5-7.1-7.3c-3.6,0.1-7.2-0.3-10.7,0.2c-6.4,0.9-15.5-0.8-15,9.5C153.6,88.5,162.7,83.1,168,84.1z M74.2,93.8
                                  C67.6,90.5,61,92.1,53,94.6C61.7,97.4,65.6,97.3,74.2,93.8z"/>
                              <path d="M117,165.1c-17.3-0.4-34.9-17.8-34.6-34.2c0.3-14.5,17.5-27.6,34.4-28.3c17.2-0.7,32.9,23.4,32.3,35.4
                                  C148.3,154,134.8,165.5,117,165.1z M118.1,159.1c10.5,0.4,20-4.6,23.1-12.4c4.3-10.7,1.7-20.8-7.4-28.3c-2.3-1.9-5-3.4-7.3-5.3
                                  c-8.8-7.3-16.3-7.2-27.7,0.3c-8.8,5.8-12,13.7-9.2,23.2C93.2,149.3,104.8,158.5,118.1,159.1z"/>
                              <path d="M163.8,105.6c-0.1,4.8-2.8,8.2-6.9,8c-4.7-0.2-8.1-3.8-9-8.6c-0.9-5.4,3.6-5.3,7.1-5.5
                                  C159.5,99.3,163.4,100.3,163.8,105.6z"/>
                              <path d="M185.4,104.9c-1.2,3.5-3.3,6.1-7.5,5.9c-3.3-0.1-4.8-2.2-4.4-5.3c0.6-4.4,3.9-5.6,7.7-5.5
                                  C184,100.1,185.4,101.8,185.4,104.9z"/>
                              <path d="M116.7,115.9c10.6-0.1,20.7,9.9,20.8,20.7c0.2,9.6-6.7,16.7-16.1,16.8c-10,0.1-24.7-13.9-25-23.6
                                  C96.2,120.9,103.4,116,116.7,115.9z M114.8,121.5c-4.7,0.9-11.6-0.9-12,7.8c-0.4,7.8,10.8,18.5,19.1,18.2
                                  c6.9-0.2,10.2-4.2,10.2-11C132.2,128.3,124.7,121.7,114.8,121.5z"/>
                            </g>
                            </svg>


                          </figure>
                          <IconLeaf />
                          <h5 class="font-size-16">Fotos Brautpaar</h5>
                      </div>
                  </li>

                  <li class="list-inline-item event-list">
                      <div class="px-4">
                          <figure class="event-date">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">
                                <g id="UwIqg7_1_">
                                      <path d="M159,179c6.2-0.2,12.3-1.9,15.1,4.1c2.4,5.3,0.8,10.7-2.3,16c-7.2,12.4-18.3,19.8-30.6,25.7c-4.3,2-9.2,3.4-14.3,1.2
                                          c-1.3-0.6-3-1-4.4-0.9c-22.4,2-33.4-14-45.2-28.7c-2.2-2.8-3.4-6-1.6-9.4c2.2-4,6-4.1,9.9-3.8c3,0.2,5.9,1.1,8.5,2.7
                                          c5.9,3.6,9.6,1.7,12.3-4.5c2.3-5.1,5.7-9.7,8.6-14.6c4.2-7.2,1.4-16.7-6.6-17.2c-16.1-1-24.5-12.5-33.7-22.5
                                          c-14.2-15.5-26.5-32.8-41.3-47.9c-3.1-3.2-6.1-6.7-8-10.9c-3.2-6.8-3.3-13.3,2.6-18.6c11.5-10.3,22.9-20.7,38.6-24.5
                                          C79,22.2,82.4,23.5,86,35.3c2,6.6,5.6,12.3,9,18.1c13,22.2,22.2,45.9,29,70.6c1.4,5.3,1.1,10.4,0.8,15.7c-0.1,1.6,0,3.2,1.9,3.4
                                          c1.9,0.3,2.5-1.4,2.9-2.8c0.4-1.4,0.6-3,0.5-4.5c-2.4-23,5.8-43.8,13.3-64.7c5-13.8,11.3-27,17.6-40.2c3.2-6.7,7.5-8.5,14.3-8.2
                                          c17.6,0.8,33,6.8,45.8,19c6.4,6.1,7.2,10.4,3.5,18.2c-15.9,33.8-42,58.4-72.4,78.7c-7.1,4.7-11,11-15.8,17
                                          c-2.1,2.7-1.5,5.6,0.5,8.4c2.9,4,5.7,8,8.5,12C149.3,181.7,155,178.7,159,179z M72.9,46.2c1.4-3.1,3-6,4-9.2
                                          c1.5-4.4-1.3-8.3-5.4-7C56.9,34.5,44,41.7,34.1,53.5c-1.7,2-3.5,4.1-1.6,7c1.6,2.5,4,3.2,6.8,3.2c2.7,0,5-1.2,7.5-2
                                          c8.2-2.8,14.7-8.8,22.7-12c-6.9,10.2-17.6,14.6-28.4,19c-1.6,0.6-4.5,0.4-3.5,3c1.6,4.3,5.2,7.5,9,9.8c3.7,2.2,6.5-1.1,8.4-3.7
                                          c5-6.8,11.1-10.3,19.9-10.4c4.7-0.1,11.2-2.6,13.7-6.2c4-5.9-2.4-10.9-5.5-15.8C79.4,39.7,76.1,43.8,72.9,46.2z M117.7,128.1
                                          c-4.3-18-12.3-37.4-21-56.6c-1.3-2.8-4.1-3.3-6.8-3c-12.4,1.2-24.2,3.6-33.6,12.9c-4.2,4.2-4.8,7.8-0.6,12.6
                                          c10,11.2,19.5,23,29.4,34.3c5.3,6,11.7,10.7,19.6,12.8C113.1,143.2,117.9,139.3,117.7,128.1z M138.6,120.8c0,2.1,0,3.3,0,4.4
                                          c0.4,9,3.2,10.6,11.2,6.5c1.3-0.7,2.5-1.6,3.8-2.4c15.8-9.9,27.5-24.2,40.4-37.2c3.9-3.9,4-7.3-0.9-9.8
                                          c-10.6-5.3-21.3-10.2-32-15.3c-4.5-2.1-7.5-0.1-9,3.8C145.6,87.4,140.8,104.3,138.6,120.8z M213.5,66.2c0.5-5.9-2.1-7.3-5.2-8.1
                                          c-12.9-3.2-24.8-8.5-35.8-16.1c-4-2.8-7.8-2.5-9.8,3c-0.6,1.8-1.6,3.4-2.5,5.1c-2.9,5.2-1.9,7.8,3.7,11.4
                                          c9.6,6.1,20.5,8.8,30.4,14C203.1,80.1,212.8,74.3,213.5,66.2z M134.2,221c12.1-0.2,31.1-15.7,34-27.7c1.7-6.8-1.8-10.2-8.6-8.1
                                          c-4,1.2-6.6,3.8-6.5,8.1c0.1,7.1-2.8,7.9-8.3,4.4c-3.5-2.2-6.3-0.8-9.2,1.4c-4.4,3.4-6.9,8-8.7,12.9
                                          C124.7,218.1,127.5,221.2,134.2,221z M122.3,205.9c0.6-0.3,1.8-0.6,2.4-1.4c3.8-4.6,7.4-9.3,11.1-14c1.7-2.2,2.4-4.7,0.4-7.2
                                          c-3.2-4-4.8-11.1-9.8-11.4c-6.4-0.4-7.5,7.3-10.4,11.8c-1.7,2.8-2.5,6.3-3.2,9.5C112.1,196.7,118.7,205.7,122.3,205.9z
                                           M211.2,51.7c1.8,0.3,3.9,0.9,5-1.1c1.2-2.2-0.8-3.3-2.1-4.6c-10.1-9.3-22.8-12.8-35.5-16.3c-2-0.6-3.9,0.1-4.6,2.2
                                          c-0.7,2.1,0.8,3.5,2.3,4.6C186.6,44.7,198.2,49.8,211.2,51.7z M110.5,209.5c-0.5-2.6-17.8-17-20.4-17c-3.6,0.1-5.1,2.3-3.1,5.2
                                          c4.9,6.9,12,11.2,19.8,14.2C108.6,212.6,110.5,212,110.5,209.5z"/>
                                      <path d="M91.9,115.6c-2.1-0.4-3.3-1.7-3.1-3.8c0.1-1.6,1.3-2.6,3-2.3c1.7,0.3,2.4,1.6,2.5,3.2C94.4,114.3,93.8,115.6,91.9,115.6z"
                                          />
                                      <path d="M90,96.3c1.5,0.2,3.1,0.5,3.2,2.4c0.1,1.7-1,2.7-2.7,2.7c-1.6,0-3-0.7-3.2-2.5C87,97,88.3,96.4,90,96.3z"/>
                                      <path d="M78.4,94.4c-0.3,1.6-1.4,2.6-3,2.5c-1.6,0-3-1-3-2.6c0-1.7,1.5-2.6,3-2.6C77,91.7,78.3,92.6,78.4,94.4z"/>
                                      <path d="M173.4,92c0.8,0,1.5,0,2.3,0.1c3.2,0.2,6.2,0.9,5.7,5.1c-0.2,1.7-1.6,2.5-3.3,2.5c-3.8-0.1-3.6-3.1-4-5.6
                                          c-0.1-0.7-0.3-1.5-0.5-2.2L173.4,92z"/>
                                      <path d="M158.9,111.1c-0.1,2-0.7,4-2.9,4.2c-2.6,0.2-4.2-1.6-4.3-4.1c-0.1-2.1,1.5-3.3,3.6-3.5C157.8,107.5,158.7,109,158.9,111.1
                                          z"/>
                                      <path d="M165.5,95c-0.8,1.9-2.2,3-4.2,2.8c-2.6-0.3-3.9-1.9-3.3-4.6c0.5-2.3,2.4-2.6,4.3-2.1C164.1,91.7,165.5,92.8,165.5,95z"/>
                                      <path d="M173.7,91.8c-0.9-0.6-1.9-1.1-2.8-1.7c-1.5-1.2-2.7-2.7-1.6-4.6c0.8-1.4,2.5-1.3,3.9-0.9c2.3,0.8,2.2,2.6,1.6,4.4
                                          c-0.3,1-0.9,2-1.4,2.9L173.7,91.8z"/>
                                      <path d="M166.8,106c1.3,0.1,2.5,0.7,2.7,2.4c0.3,2.1-0.7,3.4-2.8,3.6c-1.9,0.1-3.8-0.4-3.7-2.7C163,107.3,164.6,106.3,166.8,106z"
                                          />
                                </g>
                            </svg>


                          </figure>
                          <IconLeaf />
                          <h5 class="font-size-16">Sektempfang & Häppchen</h5>
                      </div>
                  </li>

                  <li class="list-inline-item event-list">
                      <div class="px-4">
                          <figure class="event-date">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">
                            	<g>
                            		<path d="M141.1,218.5c-5.6,0-11.1,0.1-16.7,0c-2.8-0.1-6,0.3-8.2-0.9c-11.9-6.5-25-4.9-37.6-6.2c-13.5-1.3-26.8-2.9-39.6-7.7
                            			c-6.6-2.4-10.1-7.1-12.5-12.9c-2.3-5.4-1.4-10.2,3.7-13.7c4.8-3.4,9.5-7,15.1-9c4.4-1.6,7.4-4.4,9.2-9.1
                            			c9.6-25.2,28.8-41.1,52.8-51.4c5.3-2.3,7.2-4.5,6.1-9.8c-0.2-1-0.3-2.1-0.3-3.1c-0.3-7.6,1.2-9.3,8.6-9.8
                            			c9.3-0.6,11.1,1.1,10.9,10.3c-0.1,7.5-0.2,7.9,8,9.7c20.8,4.5,36.2,16.8,48.1,33.7c4,5.7,7,12.1,8.7,18.8c1.4,5.5,4.1,8.3,9.4,9.4
                            			c2.7,0.5,5.2,1.8,7.7,2.9c11.2,5,13.7,16.3,5,25.2c-7.1,7.3-15.8,12.3-25.4,15.8C176.9,216.6,159.3,219.7,141.1,218.5z
                            			 M91.8,190.6c11.3,1.6,22.5,3.7,33.9,4.6c20.4,1.5,40.5-0.4,60-7.5c6.3-2.3,8.2-4.2,8.3-11.2c0-3.1-0.1-6.3-0.6-9.3
                            			c-3.5-19.7-13.4-34.9-30-46.7c-16.2-11.5-33.7-12.9-51.5-8.6c-16.1,3.9-28.7,14.9-39.7,27.2c-7.9,8.9-13.1,19.5-15.8,31.1
                            			c-1.9,8.2-0.2,11.5,8,13.9c3.9,1.1,6.7,3.3,9.7,5.7c-3.5-1.9-7.6-1-11.2-2c-5.9-1.7-12.4-2.9-11.8-11.6c0.3-3.8-2.4-4.8-5.6-3.1
                            			c-3.3,1.8-6.8,3.5-9.7,5.9c-6.6,5.4-6.2,13.1,0.8,18.1c1.1,0.8,2.5,1.1,3.8,1.7c10.3,4.4,21.3,6.6,32.2,7.1
                            			c13.9,0.7,27.5,3.3,41.4,3.6c7.8,0.2,14.5,4.2,21.8,4.7c12.1,1,24.3,0.6,36.3-1.7c15.4-3,29.7-8.5,41.7-19.2
                            			c8.4-7.5,7.4-17.4-3.9-20.2c-1.7-0.4-3.2-1.4-4.9-1.8c-5.3-1.1-7.6,1.1-4.9,6c2.7,4.9,0.6,7.1-2.7,9.3c-4,2.7-8.4,4.6-13.2,5.8
                            			c-6.7,1.6-13.6,2.6-20.2,4.4c-14.8,3.9-29.7,3.7-44.4,1.6C110.1,197.1,99.9,196.9,91.8,190.6z M127.8,95.1c0.2-3,0.1-5.7-3.8-5.9
                            			c-3.8-0.1-5.8,1.9-6,5.6c-0.3,3.9,1.2,7.7,4.9,8C127.5,103.2,127.2,98.4,127.8,95.1z"/>
                            		<path d="M81.1,90.9c-0.6-11.6,5-21.1,10.8-30.5c2-3.2,4.5-6.2,6.3-9.5c2.8-4.9,2.4-9.9-0.2-14.9c-0.8-1.6-0.8-3.5,1.1-4.5
                            			c1.9-1,3.2,0.3,4.3,1.7c4.2,5.3,4.3,14.2,0.7,21.5c-3.7,7.4-9.8,13.3-12.8,21.2c-3.4,8.8-5.4,17-0.8,25.8c0.8,1.6,1.4,3.5-0.5,4.5
                            			c-2.1,1.2-3.9,0.1-5.3-1.7C81.2,100.6,80.5,95.9,81.1,90.9z"/>
                            		<path d="M111.6,69.1c0,4.2-1.8,7.2-3.8,10.1c-3.9,5.5-6.5,11.4-4.5,18.5c0.5,1.6,0.9,3.6-1.2,4.4c-2.4,0.9-4-0.7-5.2-2.4
                            			c-2.5-3.4-2.2-7.3-0.7-10.9c1.9-4.8,4.4-9.4,6.8-13.9c1.5-2.8,2.6-5.6,2.2-8.9c-0.2-1.9,0.7-3.6,2.7-3.8c1.9-0.1,2.9,1.6,3.3,3.3
                            			C111.4,66.9,111.5,68.3,111.6,69.1z"/>
                            		<path d="M176.6,150.6c-0.1,0.9-0.2,2.7-0.7,4.3c-1.2,3.9-4.3,4.6-6.8,1.5c-1.7-2.1-3.1-4.6-4-7.2c-2.4-6.7-6.6-11.1-13.6-12.9
                            			c-2.8-0.8-6.7-0.6-7-4.8c-0.2-2.7,7-7.4,10.4-7.2c0.3,0,0.7,0.1,1,0.2C163,125.8,176.8,142.7,176.6,150.6z"/>
                            	</g>

                            </svg>


                          </figure>
                          <IconLeaf />
                          <h5 class="font-size-16">Dinner</h5>
                      </div>
                  </li>
                  <li class="list-inline-item event-list">
                      <div class="px-4">
                          <figure class="event-date">

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">

                            <g>
                              <path d="M172.4,135.4c8.6,5.2,18,7.1,26.3,11.3c3.9,14.6-0.8,29.2,1,42.7c-2.8,5.7-7.5,7.2-11.8,8.8c-11.5,4.4-23.1,8.8-35,12.1
                                  c-7.3,2.1-15,3-22.7,3c-7.4,0-14.8,0.2-22.2,0c-9.4-0.2-18.8-1.2-28-3.5c-7.7-1.9-15.9-1.6-24.3-6.4c-6.5-17.7-5.4-37.1-5.9-56
                                  c6.5-5.2,14.8-5.3,21-9.5c1.6-9.9,0.3-19-5.1-26.7c1.3-3.9,3.6-5.5,6.6-6.4c8.3-2.6,16.6-5.3,24.9-7.9c2.7-7.6-1.1-15.1-0.9-22.6
                                  c3.8-2.7,7.8-0.9,11.5-1.2c3.5-0.4,7.1,0.3,11-0.9c1.2-3.3,0.5-6.6-0.3-10.1c-3.5-3.1-7.6-5.7-9.5-10.5c0-3,0-6.2,0-9.1
                                  c1.8-3.5,4.3-5.4,7.5-6.3c3.5,0.3,5.5,3.5,8.8,4.6c4.3,0.1,8.7-3.7,13.5-0.8c3,4.9,3,4.9,1,11.4c-3.6,3.5-7.5,7.1-11.3,10.8
                                  c-0.8,3.2-0.7,6.4-0.2,9.8c6,6.4,15.9,2.4,21.9,8.4c5,5.7,1.8,12.9,3.1,19.7c4.8,1.9,9.7,3.9,14.4,5.8
                                  C172.4,115.5,172.8,125.5,172.4,135.4z M117.7,120.8c-2.2,4.6-6.3,4.1-9.9,3c-3.2-1-6.3-1.9-9.4-0.3c-2.8,1.4-5-1.6-8.8-0.3
                                  c-1.7,3.9-6.9,2.6-10.7,4.3c-0.4,6-0.4,6,2.8,10.7c27.3-3.4,54.6-7.9,82.3-5.1c0.2,0,0.4-0.4,0.7-0.8c1-1.8,1.1-3.7,0.2-5.7
                                  c-0.2-0.5-0.8-0.8-1.5-1.3c-2.6,0.7-5.4,1.4-7.8,2c-2.9-1.2-5.3-2.7-7.8-3c-3.5-0.5-6.8-0.2-10-2.7c-2-1.6-5.1-1.2-7.6-0.1
                                  c-1.9,0.8-3.7,1.8-5.1,2.5c-3.1-0.6-4.5-3.9-7.6-3.1c-0.3-3.4,1-6.3,2.9-9c1,0.1,1.9,0.1,2.7,0.1c2.6,1.7,1.1,6.2,5.5,6.8
                                  c2.8-0.6,3.8-4.9,7.8-5c1.3,4.3,4.8,5.6,8.8,6.2c2.6-1,3.8-4.6,6.4-4.3c4.1,1.3,2.1,6.2,5.8,6.9c4.7-2.4,5.7-3.3,6-5.6
                                  c-0.5-1.9-1-3.9-2.4-5.4c-22.1-12.5-71.7-11.6-85.2,1.4c2.3,3,4.5,6,6.3,8.4c7.2,0.4,5.4-7.2,9.3-9.5c3.5,1.9,3.2,7.1,7.8,7.3
                                  c3.5-0.2,4.3-4.8,7.8-5.1c3,0.9,2.1,4.9,4.8,6C113.7,120.1,115.8,119.8,117.7,120.8z M172.5,161.7l0.2,0.2
                                  c-0.4-2.8,0.9-4.4,3.5-5.1c3.4,1.1,2.3,5.4,5.2,6.7c4.3-0.6,4.3-0.6,10.6-5.1c0.4-2.6,0.6-5.2-0.9-7.3c-4.6-4.7-10.7-5.3-16.1-7.4
                                  c-8.8-3.3-17.8-6.2-27.2-6.6c-10.2-0.4-20.6-0.8-30.7,2c-2.8,0.8-5.8,0.9-8.7,1.2c-8.2,0.8-16.5,1-24.5,2.6
                                  c-9,1.9-18.6,2.6-26.4,8c-0.5,11.3,1.4,14.2,10.4,16.2l-0.2-0.2c0,1.2,0,2.4-1.8,3.5c-0.8,0.1-2.2,0.3-3.7,0.4
                                  c-1.8,0.1-3.6,0.2-4.7,2.8c3.2,7.4-0.9,16.6,4.7,24.7c11.3,6.1,24.3,8.1,36.9,9.1c16.3,1.3,32.7,1.9,49.1-2.3
                                  c13.2-3.3,25.5-8.6,38.1-13.1c3.2-1.1,5.5-3.7,6.3-7c1.3-6.1,2-12.3-1.6-18c-0.4-0.7-1.4-0.9-2.3-1.5c-2.1,0.9-4.3,1.7-5.9,2.4
                                  C177.7,168.2,175.2,164.8,172.5,161.7z M107.3,80.5c-0.8,2.4-2.7,4.1,0.4,6.4c2.4-1.7,4.9-4.8,9.3-2.9c2.1,0.6,1.7,4.9,4.2,4.7
                                  c3.3-0.2,3.4-3.6,5.9-3.3c2.8-0.3,3.6,2.9,5.9,3.5c2.2,0.2,3.5-2.4,5.8-1.8c2.3,0.3,3.9,3.3,7.1,2.2c-1.5-3.5-3.9-5.1-7.3-6
                                  C128.6,80.3,118.4,79.4,107.3,80.5z M139.3,93c-2.5,0.5-4.7,1.4-7,1.4c-2.2,0-4.5-0.9-7-1.5c-1.9,2.5-4.5,3.7-8,3
                                  c-1-0.9-2.2-2.1-3.5-3.3c-2.4-0.1-4.7-0.6-6.7,1.4c0,0.8,0.1,1.6,0.2,3.2c11.6,0.5,23.1,1.1,34.7,1.5c0.7,0,1.4-0.9,1.9-1.3
                                  C142.9,95.3,141.6,94.1,139.3,93z M116.7,45c-2.3,3.8-1.1,6.5,1.8,8.5c3.3,2.3,6.7,1.7,9.9-0.2c2.5-1.5,5.8-2.7,4.6-6.9
                                  c-4.2,0-6.3,3.4-9.9,4.5C121,49.1,118.9,47.2,116.7,45z"/>
                              <path d="M106.8,161.7c-1.5,4-4.9,3.8-8.1,3.5c-2.3-0.2-4.5-1.1-7.1-1.9c-5.3,4-11,6.5-16.9,2c-2.9-0.4-4.4,2.7-7,1.7l0.2,0.2
                                  c1.9-3.7,3.8-7.4,4.9-11.5c5.4-0.1,4.8,4.6,6.6,7.1c2.2,1.3,4.5,1.2,6.5,0.7c5.3-2.3,6.3-7.6,8.7-11.8c3.2,2,1.1,5.2,2.8,7.6
                                  c2.5,2.6,6.3,2,9.6,2.6L106.8,161.7z"/>
                              <path d="M133.6,158.3c1.5-2.4,3.1-4.8,4.5-7c4.6,0,3.7,4.1,5.3,6c1.6,1.2,3.4,0.7,5,1.1c3-0.9,4.3-5.4,6.9-4.3
                                  c4.4,1.6,2.5,5.7,5.4,6.9c3.8,1.7,8,0.1,12,0.9l-0.2-0.2c-0.7,2.3-2.4,3.2-4.6,3.3c-3.2,0.1-6.4,0-10,0c-1.7-1.4-3.7-3.1-6-5.1
                                  c-6.6,3.9-12.3-0.8-18.4-1.9L133.6,158.3z"/>
                              <path d="M107,161.9c1.9-4.1,6.1-9.5,9.5-11.9c3.7,3.1,1.7,9.3,8.1,11.6c-6,2.5-7.2-2-9.9-2.8c-3.2-0.8-4.6,3.7-7.9,2.8L107,161.9z
                                  "/>
                              <path d="M133.4,158.1c-1.4,4.1-4.5,3.7-8.3,3.4c2.2-4.3,5.7-2.8,8.5-3.2L133.4,158.1z"/>
                            </g>
                            </svg>


                          </figure>

                          <IconLeaf />
                          <h5 class="font-size-16">Torte</h5>
                      </div>
                  </li>
                  <li class="list-inline-item event-list">
                      <div class="px-4">
                          <figure class="event-date">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 250 250">
                            <g>
                                <path d="M53.8,198.3c0-0.8,0.1-1.6,0-2.4c-0.7-6.1,0-11.6,3.8-16.8c5.5-7.4,4.5-16.4,5.5-24.9c0.7-6.7-0.3-13.3-0.8-20
                                    c-1-13.3,2.2-25.8,14.2-34.3c6.4-4.5,12.4-9.8,19.2-13.6c9.7-5.4,15.8-13.8,21.1-22.9c3-5.1,5.5-10.5,8.4-15.7
                                    c0.8-1.4,1.7-2.8,2.6-4.1c6.5-9.3,12.4-10.2,20.8-2.8c2.6,2.3,4.8,5.2,6.6,8.2c2.3,3.9,4,8.2,5.9,12.4c6.8,14.9,7.9,30.8,8.1,46.7
                                    c0.1,5.6-1.2,11.2-4.3,16.3c-2.6,4.2-6,6.6-10.6,8.2c-4.1,1.4-7.2,0.7-10.7-1.3c-6.1-3.5-12.1-7.1-18.5-10
                                    c-5.9-2.6-12.1-4.7-18.3-6.3c-6.5-1.7-17.1,3.2-20.5,8.9c-3.9,6.6-0.1,18.2,7.5,20.4c5.1,1.4,10.8,4.6,16.3,0.3
                                    c11.5-1.4,23.1-3.3,34.1,2.6c2.5,1.3,5.4,2.2,8.2,2.7c3,0.5,4.5,1.7,5.7,4.7c2.4,6.2,5.7,12.1,8.3,18.2c0.9,2.1,1.2,4.6,1.2,7
                                    c0,4.6-0.3,9.1-0.6,13.7c-0.1,1.3-0.3,2.7-0.5,4c-0.8,5.3-1.4,6-6.6,6.3c-11.9,0.7-23.7,1.1-35.6,1.9c-4,0.3-8,1.1-12,1.7
                                    c-0.8,0.1-1.5,0.6-2.3,0.7c-16.3,2.1-32.6,4.3-49,6.2c-5.5,0.6-7.1-1.1-7.3-6.8c-0.1-3,0-6,0-8.9
                                    C53.7,198.3,53.8,198.3,53.8,198.3z M159.7,189.8c0.1-7.3-0.3-7.7-7.7-7.6c-1.1,0-2.2,0-3.2,0.1c-13.2,1-26.3,2-39.5,3.1
                                    c-12.4,1-24.7,2.1-37.1,3.4c-2.9,0.3-5.9,1-8.7,1.8c-1.1,0.3-2.8,1.5-2.8,2.3c0.2,4.2,0.6,8.5,1.4,12.7c0.2,1.1,2.5,2.5,3.7,2.4
                                    c5.9-0.3,11.8-1,17.7-1.8c11.5-1.5,23-3.2,34.5-4.5c11.2-1.3,22.5-2.1,33.8-3.4C158.9,197.6,159.6,196.6,159.7,189.8z M163.4,99.9
                                    c-0.7-17-5.4-32.6-13.1-47.3c-1.3-2.6-3.5-4.8-5.7-6.8c-2.7-2.5-4.5-2.1-6.4,1c-2.1,3.5-3.6,7.2-3.4,11.4
                                    c0.5,12.4-0.4,24.7,2.7,37c1.9,7.6,3.6,15.2,6,22.6c1.3,4.1,3.9,7.6,8.5,7.5c4.2-0.1,6.2-3.8,7.9-7.3c0.3-0.7,0.7-1.5,0.8-2.3
                                    C161.7,110.4,162.6,105.1,163.4,99.9z M71.1,119.6c1,2,1.6,3.4,2.3,4.8c1.1-0.6,2.4-1.1,3.3-1.9c2.6-2.3,4.9-5.1,7.7-7.2
                                    c7.5-5.8,15.6-9.6,25.5-6.7c5.9,1.8,12,3.1,18,4.4c1.7,0.4,4.1,1,5.1,0.1c1-0.8,0.9-3.4,0.7-5.1c-0.4-2.6-1.6-5.2-2-7.8
                                    c-1.7-12.6-4.3-25.1-3.4-37.9c0.1-0.8-0.3-1.6-0.5-2.4c-0.6,0.5-1.3,0.9-1.7,1.6c-1,1.6-1.7,3.4-2.7,4.9
                                    c-6.6,10.3-14.4,19.6-25.5,25.5c-8.9,4.8-16.3,11.6-22.8,19.2C73.2,113.6,72.4,116.9,71.1,119.6z M100.9,176.7
                                    c13.8-0.6,26.2-4.3,37.4-12.1c1.8-1.2,3.4-2.6,5-4.1c2.1-2.1,2.2-4.4-0.1-6.3c-5.2-4.3-11.1-6.8-17.9-5.9c-1,0.1-2.2,1.9-2.7,3.1
                                    c-0.3,0.8,0.5,2.1,0.6,3.1c0.2,4.3-2.9,6.5-6,3.8c-3.4-3-6.6-2.7-10.4-2.2c-2.6,0.3-5.4,0-8-0.7c-8.5-2.3-14.8,1-20,7.4
                                    c-3.7,4.6-2.6,8.7,3.2,10.2C88.2,174.7,94.6,175.5,100.9,176.7z M130.3,179.1c0,0.1,0,0.2,0,0.3c5.3-0.6,10.7-1,16-1.8
                                    c12.7-2,13.8-4.3,7.6-15.2c-1.1-1.9-2.4-3.1-4.3-0.9c-8,9.3-18.2,13.8-30.2,15c-0.5,0.1-1,0.9-1.5,1.4c0.6,0.4,1.2,1.1,1.8,1.1
                                    C123.3,179.2,126.8,179.1,130.3,179.1z M72.6,184.3c10-1.2,19.9-2.4,29.7-3.6c0.7-0.1,1.3-0.5,1.9-0.7c-0.8-0.2-1.5-0.5-2.3-0.5
                                    c-4-0.1-8.1,0-12.1-0.1c-5.3-0.2-15.3-3-17.8-5.9c-2.5-2.9-3.9-2-5.6,0.3c-0.8,1.1-1.4,2.3-2,3.5c-2.3,5.2-1.3,6.8,4.3,7
                                    C70,184.4,71.4,184.3,72.6,184.3z M71.7,151.5C71.7,151.5,71.7,151.5,71.7,151.5c0,0.8-0.2,1.7,0,2.4c0.3,1.2,0.9,2.3,1.4,3.4
                                    c0.9-0.4,1.9-0.8,2.8-1.3c0.9-0.6,1.7-1.3,2.6-1.9c3.1-2.6,3.1-3.1,0.2-5.3c-0.9-0.6-1.8-1.2-2.7-1.8c-3.2-2-3.9-1.7-4.2,2.2
                                    C71.7,149.9,71.7,150.7,71.7,151.5z M109.8,179.8c-0.4,0.2-0.9,0.3-1.3,0.5c0.4,0.2,0.8,0.4,1.3,0.4c0.4,0,0.9-0.3,1.3-0.5
                                    C110.7,180.1,110.3,179.9,109.8,179.8z"/>
                                <path d="M186.6,41c-0.3,0.5-0.6,1.6-1.3,2.2c-3.4,3-6.9,6.1-10.6,8.8c-0.9,0.7-3.4,0.4-4.1-0.3c-0.8-0.9-1.1-3.5-0.4-4.2
                                    c3.5-3.3,7.2-6.5,11.2-9.2C183.4,36.9,186.3,38.4,186.6,41z"/>
                                <path d="M193.5,68c-0.4,0.5-0.7,1.5-1.3,1.7c-2.9,1.1-5.9,2.3-9,3c-1,0.2-2.7-0.4-3.3-1.2c-0.6-0.9-0.8-2.6-0.3-3.6
                                    c1.7-3.7,10.7-4.7,13.4-1.5C193.3,66.8,193.3,67.4,193.5,68z"/>
                                <path d="M189.2,96.3c-0.4-0.1-2.7,0-4.4-1c-1.2-0.7-2.1-2.9-2.1-4.5c0-0.8,2.4-2.3,3.5-2.2c2.8,0.4,5.6,1.5,8.2,2.7
                                    c0.9,0.4,1.3,2.1,2,3.2c-1,0.6-2,1.3-3.1,1.8C192.6,96.5,191.7,96.3,189.2,96.3z"/>
                                <path d="M192.6,117.4c-1.7,0.8-2.7,1.7-3.8,1.7c-3,0-6.8-3.5-7.1-6.5c-0.1-0.8,0.5-2.4,1.2-2.6c1.4-0.5,3.7-1,4.5-0.2
                                    C189.2,111.8,190.6,114.4,192.6,117.4z"/>
                                <path d="M113.7,162.1c0,2.4-2.3,4.3-5,4.2c-2.4-0.1-4.1-1.3-4.4-3.8c-0.3-2,2.3-4.1,5-4.1C111.8,158.4,113.4,159.6,113.7,162.1z"
                                    />
                            </g>
                            </svg>
                          </figure>
                          <IconLeaf />
                          <h5 class="font-size-16">Feiern</h5>
                      </div>
                  </li>
              </ul>
          </div>

      </section>

      <section>
        <div className="well">
          <h1>Danke an euch alle</h1>
          <p>Wir möchten uns noch einmal bei allen bedanken, die diesen für uns perfekten Tag mit uns verbracht und überhaupt erst ermöglicht haben.</p>
          <p>Wir hatten eine wunderbare Zeit und freuen uns darauf, euch bald alle wieder zu sehen.</p>

          <h4>Fotos</h4>
          <p>Die Fotos von Grubenglück sind eingetroffen und wir haben die Galerie für euch freigegeben.</p>
        </div>
      </section>



      <section className="mt-5">
      <hr className="pb-5" />
        <h4>Ich war schon länger nicht mehr hier... was hat sich zwischenzeitlich getan?</h4>

        <ul className="newsflash text-left text-muted mt-5">
          <li>Update Unser Tag: Danke</li>
          <li>Update Gallerie: Links zu Hochzeitsfotos eingefügt</li>
          <li>Update Unser Tag: wichtige Ankündigungen</li>
          <li>Update Gallerie: Polterabendbilder</li>
          <li>Update Kontakt: Steffis korrekte Handynummer eingetragen</li>
          <li>Update Hochzeits-ABC: Oma Rosa durch DaRino ersetzt</li>
          <li>Update Unser Tag: Tagesplanung mit Anfangszeiten</li>
          <li>Update Hochzeits-ABC: X-tra Corona Mist - 2G+ Regelung</li>
          <li>Update Hochzeits-ABC: Mobilität und Parken - Abstellmöglichkeit von Wohnmobilen</li>
          <li>Update Anreise / Unterkunft: Freischaltung Schulte-Scherlebeck</li>
          <li>Update Kontakt: Telefonnummern Trauzeugen</li>
        </ul>
      </section>
    </div>
  );
}

export default ContentInfo;
