import React from 'react';
import logo from './logo.svg';
import './App.scss';

// components
import Login from './components/loginSection/Login';
import SecureContent from './components/secureSection/SecureContent';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      secureContent: 0,
      version: 1  //update to new Versio if update
    };
  }

  componentDidMount(){
    if(cookies.get('weddingCookie') === 'go4it'){
      this.setState({ secureContent: 1 })
    }

    if(cookies.get('weddingCookieUpdate')){
      console.log(cookies.get('weddingCookieUpdate'));
      this.setState({version: cookies.get('weddingCookieUpdate')})
    }else{
      cookies.set('weddingCookieUpdate', {"info":0,"ablauf":0,"logistic":0,"fotos":0,"contact":0}, { path: '/', sameSite: 'Strict' });
    }
  }

  render() {
    if(this.state.secureContent === 1){
      return(
        <SecureContent />
      )
    }else{
      return(
        <Login />
      );
    }
  }
}

export default App;
