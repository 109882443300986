import Countdown from 'react-countdown';

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span></span>;
  } else {
    // Render a countdown
    return <div className="countdown my-5"><h4>Nur noch {days} Tage und {hours} Stunden bis zu unserer Sause.</h4></div>;
  }
};

function IconCountdown() {
  return (
    <Countdown
      date={'April 23, 2022 13:30:00'}
      renderer={renderer}
    />
  );
}

export default IconCountdown;
