import Carousel from 'react-bootstrap/Carousel';

function ContentGallery() {
  return (
    <div>
      <aside className="jumbotron p-0 text-center">
        <figure className="banner">
          <img src="gfx/fotograf.jpg" className="img-fluid" alt="Fotos Banner" />
          <h2>Fotos von der Hochzeit</h2>
        </figure>
      </aside>

      <section>
        <div className="card-deck card-deck-fotos">
          <div className="card">
            <a href="https://drive.google.com/drive/folders/1DT0_ET0ItEo0gy4eWtySSyJbSHW1cumj?usp=sharing" target="_blank" rel="noreferrer">
              <img src="gfx/image-standesamt.jpg" className="card-img-top" alt="Standesamt Bild" />
              <div className="card-img-overlay">
                <h5 className="card-title">Standesamt</h5>
              </div>
            </a>
          </div>
          <div className="card">
            <a href="https://drive.google.com/drive/folders/10qskrdeRJjhYrSy4jbV9yfjQGxp73R0K?usp=sharing" target="_blank" rel="noreferrer">
              <img src="gfx/image-polter.jpg" className="card-img-top" alt="Polterabend Bild" />
              <div className="card-img-overlay">
                <h5 className="card-title">Polterabend</h5>
              </div>
            </a>
          </div>
          <div className="card">
            <a href="https://drive.google.com/drive/folders/1-U9Xl3HaLIpIP_SKCyLFKLWzNVFjUFYl" target="_blank" rel="noreferrer">
              <img src="gfx/image-kirche.jpg" className="card-img-top" alt="Kirche Bild" />
              <div className="card-img-overlay">
                <h5 className="card-title">Trauung - private Fotos</h5>
              </div>
            </a>
          </div>
        </div>
        <div className="card-deck card-deck-fotos mt-4">
          <div className="card">
            <a href="https://galerie.grubenglueck.com/-saminarobert/login" target="_blank" rel="noreferrer">
              <img src="gfx/gallerie-grubenglueck.jpg" className="card-img-top" alt="Standesamt Bild" />
              <div className="card-img-overlay">
                <h5 className="card-title">Trauung - Grubenglück Fotos</h5>
              </div>
            </a>
            <div className="text-left my-3 p-3">
              <h4>So geht es:</h4>
              <ol>
                <li>Oben auf den <a href="https://galerie.grubenglueck.com/-saminarobert/login" target="_blank" rel="noreferrer">Link</a> klicken</li>
                <li>Das Passwort für die Galerie ist: 2304saminarobert</li>
                <li>Die Galerie ist für 6 Monate online (danach haben wir aber natürlich noch alle Fotos)</li>
                <li>Ihr könnt euch die Fotos ganz normal in der Galerie anschauen</li>
                <li>Falls ihr Bilder herunterladen oder teilen wollt bzw. im Shop für z.B. persönliche Fotoalben (bis Anfang Juni auch mit 20% Rabatt) nutzen möchtet, müsst ihr euch einmalig kostenlos registrieren</li>
              </ol>
            </div>
          </div>
        </div>

      </section>

    </div>
  );
}

export default ContentGallery;
