import React from 'react';
import { Tab, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class MainNavigation extends React.Component {
  render() {
    return (
      <>
        <TabList>
          <Tab>Title 1</Tab>
          <Tab>Title 2</Tab>
        </TabList>
      </>
    )
  }
}

export default MainNavigation;
