import './content.scss';

const abcData = [
  {info: 'Die Liebfrauen Kirche liegt im Herzen von Gelsenkirchen. Eine Anreise kann mit Auto, Zug (Hauptbahnhof in Laufweite) oder Bus / Bahn problemlos erfolgen. Die Kirche selbst bietet nur Parkmöglichkeiten für eine überschaubare Anzahl Fahrzeuge; ein Parkhaus ist aber in wenigen Metern erreichbar. Detaillierte Infos auf der Anreise Seite <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" class="nonfeather"><path d="M7 13.5c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm9 1c0-.276-.224-.5-.5-.5h-7c-.276 0-.5.224-.5.5s.224.5.5.5h7c.276 0 .5-.224.5-.5zm4-1c0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5.672 1.5 1.5 1.5 1.5-.672 1.5-1.5zm-17.298-6.5h-2.202c-.276 0-.5.224-.5.5v.511c0 .793.926.989 1.616.989l1.086-2zm19.318 3.168c-.761-1.413-1.699-3.17-2.684-4.812-.786-1.312-1.37-1.938-2.751-2.187-1.395-.25-2.681-.347-4.585-.347s-3.19.097-4.585.347c-1.381.248-1.965.875-2.751 2.187-.981 1.637-1.913 3.382-2.684 4.812-.687 1.273-.98 2.412-.98 3.806 0 1.318.42 2.415 1 3.817v2.209c0 .552.448 1 1 1h1.5c.552 0 1-.448 1-1v-1h13v1c0 .552.448 1 1 1h1.5c.552 0 1-.448 1-1v-2.209c.58-1.403 1-2.499 1-3.817 0-1.394-.293-2.533-.98-3.806zm-15.641-3.784c.67-1.117.852-1.149 1.39-1.246 1.268-.227 2.455-.316 4.231-.316s2.963.088 4.231.316c.538.097.72.129 1.39 1.246.408.681.81 1.388 1.195 2.081-1.456.22-4.02.535-6.816.535-3.048 0-5.517-.336-6.805-.555.382-.686.779-1.386 1.184-2.061zm11.595 10.616h-11.948c-1.671 0-3.026-1.354-3.026-3.026 0-1.641.506-2.421 1.184-3.678 1.041.205 3.967.704 7.816.704 3.481 0 6.561-.455 7.834-.672.664 1.231 1.166 2.01 1.166 3.646 0 1.672-1.355 3.026-3.026 3.026zm5.526-10c.276 0 .5.224.5.5v.511c0 .793-.926.989-1.616.989l-1.086-2h2.202z"></path></svg>', name: 'Anreise', key: 'A', newInfo: 0},
  {info: 'Kirche und Location sind ebenerdig und alle Räumlichkeiten stufenlos erreichbar.', name: 'Barrierefreiheit', key: 'B', newInfo: 0},
  {info: 'Respektiert bitte wie auch unsere Fotografen den Ablauf der kirchlichen Trauung. Stummmodus und kein Blitzlichtgewitter wären schön :)', name: 'Checkt bitte Eure Handys!', key: 'C', newInfo: 0},
  {info: 'wir feiern elegant rustikal. Kostümierungen sind demnach nicht erforderlich, aber ihr dürft euch gerne festlich in Schale werfen.', name: 'Dresscode & Style', key: 'D', newInfo: 0},
  {info: '...mögen wir sehr. DaRino und die Form-Bar sorgen für einen Snack nach der Kirche, eine wunderbare Torte und ein köstliches Buffet, bei dem für alle Ernährungsvorlieben und Bedürfnisse etwas dabei sein wird.', name: 'Essen', key: 'E', newInfo: 0},
  {info: 'Wir konnten die lieben Menschen von Grubenglück gewinnen. Die beiden werden alles im Blick haben und unaufdringlich unsere Gruppe sowie Details der Hochzeit festhalten. Alle Aufnahmen können hinterher eingesehen und auf Wunsch bestellt werden.', name: 'Fotos', key: 'F', newInfo: 0},
  {info: 'Es gibt keinen klassischen Gabentisch - unser Haushalt ist bereits gut ausgestattet, so dass wir keine Mitgift benötigen. Wir würden gerne nach Skandinavien flittern, daher freuen wir uns über Geldgeschenke.', name: 'Geschenke', key: 'G', newInfo: 0},
  {info: 'für einen unbeschwerten Tag ist gesorgt, daher benötigen wir keine Hilfe. Falls ihr euch dennoch mit einbringen möchtet, sprecht uns einfach an.', name: 'Hilfe bei Orga', key: 'H', newInfo: 0},
  {info: 'bitten wir um einen maßvollen Umgang mit Plastikartikeln und Deko - gerade im Umgang mit Geschenken.', name: 'Im Sinne der Nachhaltigkeit', key: 'I', newInfo: 0},
  {info: 'Priester Christoph wird in der Liebfrauen-Kirche durch die Trauung führen. Musikalisch wird uns voraussichtlich der GleisX Chor begleiten. Werft doch gerne einen Blick in die Bilder-Gallerie. Bei aller Feierlichkeit darf trotzdem gerne gelacht und geweint werden!', name: 'Ja-Wort in der Liebfrauen-Kirche', key: 'J', newInfo: 0},
  {info: 'Schleifchen für alle nach der Kirche. Im Autokorso fahren wir dann bis zur Location. Das Brautpaar verabschiedet sich ggf. zwischendurch zum Spaziergang mit Fotograf.', name: 'Korso mit Autos', key: 'K', newInfo: 0},
  {info: 'auf dem Spargelhof Schulte-Scherlebeck. Lasst euch gerne von den ersten Bildern bei den Fotos & Impressionen inspirieren.', name: 'Location', key: 'L', newInfo: 0},
  {info: 'Vor dem Hof gibt es ausreichend Parkplätze, die aber bis abends auch von Publikumsverkehr mitgenutzt werden. Nach Absprache mit dem Hof ist es auch möglich, auf diesem Parkplatz mit eurem Wohnmobil zu nächtigen, falls gewünscht. Wer mit öffentlichen Verkehrsmitteln anreist, wird einen Fußweg von ca. 10 min einplanen müssen.', name: 'Mobilität & Parken', key: 'M', newInfo: 1},
  {info: 'Brautenführung und ähnliche Traditionen - im Zweifel bitte mit den Trauzeugen absprechen.', name: 'No-Gos', key: 'N', newInfo: 0},
  {info: 'Gibt es leider nicht - aber bis mindestens 3:30 können wir ausgelassen feiern.', name: 'Open End', key: 'O', newInfo: 0},
  {info: 'Geplant für den 26.3.2022. Gästelisten von Polterabend und Kirche werden sich aufgrund der großen Anzahl unserer Freunde und Bekannten unterscheiden. Bitte habt dafür Verständnis...', name: 'Polterabend', key: 'P', newInfo: 0},
  {info: 'haben wir bei zwei Unterküften für euch vorreserviert. Meldet euch dort bitte für eine verbindliche Buchung. Schaut einmal in die Sektion Anreise und Unterkünfte für alle Infos und den Kontakt.', name: 'Quartiere für die Nacht', key: 'Q', newInfo: 0},
  {info: 'sind erlaubt. Für die Koordination sind die Trauzeugen der erste Ansprechpartner.', name: 'Reden', key: 'R', newInfo: 0},
  {info: 'auf einer entsprechenden Tafel findet ihr euren Platz - auf besondere Bedürfnisse haben wir bereits Rücksicht genommen. Nach dem Essen wird diese natürlich aufgelöst.', name: 'Sitzordnung', key: 'S', newInfo: 0},
  {info: 'Steffi & Lars (und sicherlich auch Marc & Daniel) sind die Vertrauten für die kirchliche Hochzeit.', name: 'Trauzeugen', key: 'T', newInfo: 0},
  {info: 'Fühlt ihr euch inspiriert? Dann sprecht eure Ideen / Pläne einfach rechtzeitig mit den Trauzeugen ab.', name: 'Unterhaltung & Spiele', key: 'U', newInfo: 0},
  {info: 'haben wir :)', name: 'Vorfreude', key: 'V', newInfo: 0},
  {info: 'könnt ihr an dem Abend beim DJ abgeben. Bei Schlager und Reggae setzt unsere Partylaune leider aus,', name: 'Wünsche (Lieder)', key: 'W', newInfo: 0},
  {info: '...hat bei uns hoffentlich nichts verloren. Beachtet ansonsten die für den Tag aktuellen Regelungen. Derzeit planen wir mit 2G+ inkl. tagesaktuellem Test - für ein entspanntes Feiern.', name: 'X-tra Corona Mist', key: 'X', newInfo: 1},
  {info: 'Kinder sind willkommen. Falls ihr eure Kinder mitbringt (bitte gebt uns zur Planung entsprechend Bescheid), wird es eine Kinderecke und auf dem Hof genug Platz zum Toben geben. Bitte beachtet jedoch, dass unsere wunderschöne Location keine separaten Räumlichkeiten zur Unterbringung müder Kinder hat.', name: 'Youngsters', key: 'Y', newInfo: 0},
  {info: 'Wir freuen uns auf euch!', name: 'Zu guter Letzt', key: 'Z', newInfo: 0}
];

const abc = abcData.map(({info, name, key, newInfo}) =>
  <li key={key} className={(newInfo == 1 ? "newInfo " : "") + "d-flex justify-content-between align-items-center flex-column flex-lg-row"}>
    <div className="key">{key}</div>
    <div className="key-container">
      <div className="name text-center text-lg-left"><small>...wie:</small> {name}</div>
      <div className="info" dangerouslySetInnerHTML={{__html: info }}></div>
    </div>
  </li>
)

function ContentABC() {
  return (
    <div>
      <aside className="jumbotron p-0 text-center">
        <figure className="banner">
          <img src="gfx/location.jpg" className="img-fluid" alt="Location Banner" />
          <h2>Hochzeits-ABC</h2>
        </figure>

      </aside>

      <section>
        <ul className="abc">
          {abc}
        </ul>
      </section>
    </div>
  );
}

export default ContentABC;
